import { useContext } from 'react'
import SubmitModalContext from '../context/SubmitModalProvider'

export default function Bottom() {
  const { setIsOpen } = useContext(SubmitModalContext)
  return (
    <div className="fixed left-0 bottom-0 w-full z-10 xl:hidden ">
      <div className="absolute w-full flex bottom-0 items-center py-2 px-5">
        <div
          onClick={() => setIsOpen(true)}
          className="w-full bg-[#fff] h-[52px] flex justify-center items-center border-2 rounded-lg border-[#e73223] cursor-pointer"
        >
          <p className="text-lg font-bold text-[#e73223]">하이오더 도입문의</p>
        </div>
      </div>
    </div>
  )
}
