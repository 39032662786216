import { useContext } from 'react'
import SubmitModalContext from '../context/SubmitModalProvider'

export default function FixedBar() {
  const { setIsOpen } = useContext(SubmitModalContext)
  return (
    <div className="right-0 bottom-0 fixed w-[200px] mb-20 mr-10 invisible lg:visible cursor-pointer z-50">
      <ul>
        <li
          onClick={() => setIsOpen(true)}
          className="bg-white font-bold text-center rounded-[500px] h-20 items-center flex justify-center text-gray-600 border-gray-500 border-2 shadow-xl gap-5 my-8"
        >
          <img src="free-icon-edit-3394365.png" className="h-8" alt="작성" />
          상담 신청
        </li>
      </ul>
    </div>
  )
}
