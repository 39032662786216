import './App.css'
import FixedBar from './component/FixedBar'
import Header from './component/Header'
import Bottom from './component/Bottom'
import ModalProvider from './provider/ModalProvider'
import ContextProvider from './provider/ContextProvider'
import Router from './router'

function App() {
  return (
    <>
      <ContextProvider>
        <ModalProvider />
        <Header />
        <Router />
        <FixedBar />
        <Bottom />
      </ContextProvider>
    </>
  )
}

export default App
