import { useContext, useEffect, useState } from 'react'
import Modal from './Modal'

import SubmitModalContext from '../context/SubmitModalProvider'
import { postDocument } from './../api/document.api'

export default function SubmitModal() {
  const { isOpen, setIsOpen } = useContext(SubmitModalContext)
  const [document, setDocument] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    content: '',
    place: '',
  })
  const handleSubmit = event => {
    event.preventDefault() // 기본 동작인 페이지 이동을 막음
    postDocument(document)
    setIsOpen(false)
    // 여기에서 추가적인 로직을 수행할 수 있습니다.
  }

  useEffect(() => {
    setDocument({
      name: '',
      email: '',
      company: '',
      phone: '',
      content: '',
    })
  }, [isOpen])
  const bodyContent = () => {
    return (
      <div className="w-full h-full">
        <div className="">
          <section className=" pb-[100px]">
            <form
              onSubmit={handleSubmit}
              class="container mt-16 lg:max-w-[70%] max-w-[85%] md:mx-auto mx-auto"
            >
              <div class="grid grid-cols-1 gap-x-8 gap-y-6 w-[90%]">
                <div class="sm:col-span-2">
                  <label
                    for="company"
                    class="block text-sm font-semibold md:text-lg leading-6 text-gray-900"
                  >
                    이름 <label className="text-red-500">*</label>
                  </label>
                  <div class="mt-2.5">
                    <input
                      required
                      type="text"
                      name="company"
                      id="company"
                      autocomplete="organization"
                      placeholder="이름을 입력해주세요."
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      onChange={e =>
                        setDocument({ ...document, name: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label
                    for="company"
                    class="block text-sm font-semibold md:text-lg leading-6 text-gray-900"
                  >
                    상호명 <label className="text-red-500">*</label>
                  </label>
                  <div class="mt-2.5">
                    <input
                      required
                      type="text"
                      name="company"
                      id="company"
                      autocomplete="organization"
                      placeholder="상호명을 입력해주세요."
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      onChange={e =>
                        setDocument({ ...document, company: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="email"
                    class="block text-sm font-semibold md:text-lg leading-6 text-gray-900"
                  >
                    이메일 <label className="text-red-500">*</label>
                  </label>
                  <div class="mt-2.5">
                    <input
                      required
                      type="email"
                      name="email"
                      id="email"
                      autocomplete="email"
                      placeholder="이메일을 입력해주세요."
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      onChange={e =>
                        setDocument({ ...document, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="phone"
                    class="block text-sm font-semibold md:text-lg leading-6 text-gray-900"
                  >
                    연락처 <label className="text-red-500">*</label>
                  </label>
                  <div class="mt-2.5">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      required
                      autocomplete="tel"
                      placeholder="연락처를 입력해주세요."
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      onChange={e =>
                        setDocument({ ...document, phone: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="place"
                    class="block text-sm font-semibold md:text-lg leading-6 text-gray-900"
                  >
                    설치 장소 <label className="text-red-500">*</label>
                  </label>
                  <div class="mt-2.5">
                    <input
                      type="tel"
                      name="place"
                      id="place"
                      required
                      autocomplete="tel"
                      placeholder="설치 장소를 입력해주세요."
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      onChange={e =>
                        setDocument({ ...document, place: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="message"
                    class="block text-sm font-semibold md:text-lg leading-6 text-gray-900"
                  >
                    문의내용
                  </label>
                  <div class="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows="4"
                      placeholder="문의내용을 입력해주세요"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      onChange={e =>
                        setDocument({ ...document, content: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="block text-sm font-semibold leading-6 text-gray-900 pt-10">
                <h1>[필수] 고객정보 수집 동의</h1>
              </div>
              <table class="table-fixed w-fullborder-separate text-center">
                <colgroup>
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                </colgroup>
                <thead className="bg-[#f1f2fa]">
                  <tr>
                    <th
                      scope="col"
                      className="border-t-8 border-t-[#ccc] border-b-2 border-b-black"
                    >
                      목적
                    </th>
                    <th
                      scope="col"
                      className="border-t-8 border-t-[#ccc] border-b-2 border-b-black"
                    >
                      항목
                    </th>
                    <th
                      scope="col"
                      className="border-t-8 border-t-[#ccc] border-b-2 border-b-black"
                    >
                      보유기간
                    </th>
                    <th
                      scope="col"
                      className="border-t-8 border-t-[#ccc] border-b-2 border-b-black"
                    >
                      동의여부
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="lg:text-lg text-xs text-gray-500 font-semibold">
                    <td className=" border-b-2 border-b-gray-300">
                      상담접수 시 답변 용도
                    </td>
                    <td className=" border-b-2 border-b-gray-300">
                      회사명/이름, 연락처, 이메일
                    </td>
                    <td className=" border-b-2 border-b-gray-300">
                      처리 완료 후<br /> 6개월
                    </td>
                    <td className=" border-b-2 border-b-gray-300">
                      <span class="inline-block relative">
                        <input
                          type="radio"
                          id="radios1-1"
                          name="options1"
                          class="w-5 h-5 top-1/2 left-0 absolute translate-y-[-50%]"
                          required
                        />
                        <label
                          for="radios1-1"
                          class="pl-[30px] lg:text-lg text-xs"
                        >
                          동의함
                        </label>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-10 flex justify-center">
                <button
                  type="submit"
                  class="bg-[#d71825d8] text-white font-bold rounded-2xl py-2 min-w-[200px] w-1/4 block"
                >
                  상담 신청하기
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    )
  }
  const title = () => {
    return (
      <h1 className="text-3xl font-extrabold">
        <span className="text-[#e73223]">하이오더</span>
        상담문의
      </h1>
    )
  }
  return (
    <Modal
      ModalContext={SubmitModalContext}
      title={title()}
      bodyContent={bodyContent()}
      //backButton={backButton()}
    />
  )
}
