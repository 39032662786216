import { createContext, useState } from 'react'

const SubmitModalContext = createContext({})

export const SubmitModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openSubmitModal = () => {
    setIsOpen(true)
  }

  const closeSubmitModal = () => {
    setIsOpen(false)
  }

  return (
    <SubmitModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        openModal: openSubmitModal,
        closeModal: closeSubmitModal,
      }}
    >
      {children}
    </SubmitModalContext.Provider>
  )
}

export default SubmitModalContext
