import { useNavigate } from 'react-router-dom'
import Cost from '../component/Cost'
import { postDocument } from './../api/document.api'
import { useState } from 'react'
import LazyLoad from 'react-lazyload'
import { screen } from '@testing-library/react'

export default function HiOrder() {
  const navigte = useNavigate()
  const [document, setDocument] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    content: '',
    place: '',
  })
  const handleSubmit = event => {
    event.preventDefault() // 기본 동작인 페이지 이동을 막음
    postDocument(document)
    navigte(0)
    // 여기에서 추가적인 로직을 수행할 수 있습니다.
  }
  return (
    <div className="w-full h-full">
      <div className="">
        <section className="">
          <div className="w-full relative h-[800px] overflow-hidden flex mb-20">
            <div className="z-10 text-center text-3xl text-white w-full flex justify-center align-middle flex-col gap-y-10">
              <h1 className="text-6xl font-extrabold">하이오더</h1>
              <h2>주문과 결제가 한번에 가능한 스마트한 태블릿 메뉴판</h2>
              <h2>프리미엄 테이블 오더</h2>
            </div>
            <LazyLoad>
              <img
                src="miti-qYreP9QOdrk-unsplash.webp"
                alt="main"
                className="z-0 block absolute left-1/2 translate-x-[-50%] top-1/2 translate-y-[-50%] max-w-[2000px]"
              />
            </LazyLoad>
          </div>
          {/* 1번 */}
          <div className="container mx-auto p-4 my-6 space-y-2 text-center text-gray-600">
            <h2 className="lg:text-5xl md:text-4xl text-2xl font-medium ">
              품질도 High! 안정성도 High!
            </h2>
            <h2 className="lg:text-5xl md:text-4xl text-2xl font-medium flex justify-center">
              KT 프리미엄
              <span className="lg:text-5xl md:text-4xl text-2xl font-black">
                테이블 오더 서비스
              </span>
            </h2>
            <h2 className="lg:text-5xl md:text-4xl text-2xl font-medium flex justify-center">
              <span className="lg:text-5xl md:text-4xl text-2xl font-bold text-[#D92C38]">
                ‘하이오더’
              </span>
              를 만나보세요!
            </h2>
          </div>
          {/* 동영상 */}
          <LazyLoad className="lg:h-[608px] md:h-[400px] h-[400px]">
            <div className="lg:h-[608px] md:h-[400px] h-[400px] mx-auto container">
              <iframe
                allowFullScreen=""
                frameBorder="0"
                height="100%"
                src="https://www.youtube.com/embed/sT9ui4Xlvhg?mute=1&amp;&amp;rel=0&amp;loop=1&amp;playlist=sT9ui4Xlvhg"
                tabIndex="0"
                title="[신상EASY] 품질도 High! 안정성도 High! 프리미엄 테이블 오더 서비스 '하이오더'동영상"
                width="100%"
              ></iframe>
            </div>
          </LazyLoad>
          {/* / */}
          <div className="container mx-auto mt-20">
            <div>
              <LazyLoad>
                <img src="202305021710260007053.webp" alt="asd" />
              </LazyLoad>
            </div>
          </div>
          {/* 2번 */}
          <dl className="container mx-auto flex justify-center mt-16 border rounded-[20px] p-4">
            <dt className="flex lg:flex-row flex-col justify-center items-center pr-4">
              <img
                src="pi1560-highorder_icon.png"
                alt="img"
                className="pr-3"
              ></img>
              <span className="text-xs lg:text-xl font-semibold relative">
                <span className="before: block absolute left-0 bottom-0 w-full h-3 bg-[#ffeeac] z-[-1] " />
                하이오더란?
              </span>
            </dt>
            <dd className="flex justify-center items-center text-xs lg:text-base">
              손님이 직접 테이블에서
              <br />
              주문과 결제, 한번에 가능한 테이블오더 서비스 입니다.
            </dd>
          </dl>
          {/* 3번 */}
          <div className="container lg:mx-auto py-10 my-6 text-center flex items-center justify-center relative">
            <h2 className="lg:text-[40px] text-[20px] font-bold text-[#D92C38] ">
              KT 하이 오더
            </h2>
            <h2 className="lg:text-[40px] text-[20px] font-bold ">
              를 쓰면 이런 점이 좋아요
            </h2>
          </div>

          {/* 4번 */}
          <div className="container mx-auto grid justify-center gap-3 grid-cols-1 sm:grid-cols-2">
            <div className="flex flex-col items-center p-4 bg-[#F4F7F9] rounded-2xl">
              <h3 className="my-3 lg:text-3xl text-lg font-semibold">
                테이블 회전율 개선*
              </h3>
              <img src="pi1560-like_image-1.webp" alt="회전율"></img>
              <div className="space-y-1 leadi">
                {/* <p className="lg:text-base text-xs">
                  *대전 ○○ 짬뽕 (메뉴판 12개+알림판 도입) 사례
                </p> */}
              </div>
            </div>
            <div className="flex flex-col items-center p-4 bg-[#F4F7F9] rounded-2xl">
              <h3 className="my-3 lg:text-3xl text-lg font-semibold">
                인건비 절감 가능
              </h3>
              <img src="pi1560-like_image-2.webp" alt="인건비 절감"></img>
              {/* <div className="space-y-1 leadi">
                <p className="lg:text-base text-xs">
                  **’23년 최저시급 9,620원 주 40시간 유급주휴포함 기준
                </p>
                <p className="lg:text-base text-xs">
                  **후불형 메뉴판 15대 + 알림판 1대 기준
                </p>
              </div> */}
            </div>
            <div className="flex flex-col items-center p-4 bg-[#F4F7F9] rounded-2xl">
              <h3 className="my-3 lg:text-3xl text-lg font-semibold">
                편리한 매장 운영 관리
              </h3>
              <img src="pi1560-like_image-3.webp" alt="운영 관리"></img>
              <div className="space-y-1 leadi pb-2"></div>
            </div>
            <div className="flex flex-col items-center p-4 bg-[#F4F7F9] rounded-2xl">
              <h3 className="my-3 lg:text-3xl text-lg font-semibold">
                손님 편의성 증가
              </h3>
              <img src="pi1560-like_image-4.webp" alt="편의성 증가"></img>
              <div className="space-y-1 leadi pb-2"></div>
            </div>
          </div>
          {/* 5번 */}
          <div className="container mt-[84px] mx-auto grid  grid-cols-1">
            <div className="w-full relative">
              <div className="relative pb-[41px]">
                <div className="rounded-3xl text-center text-3xl flex justify-center leading-[59px] border font-bold border-gray-500 text-gray-500 w-fit mx-auto px-10 my-20">
                  하이오더 도입 전
                </div>
                <div className="flex justify-center">
                  <img src="2023050217205200314342.webp" alt="도입 전" />
                </div>
              </div>
            </div>
            <div className="w-full relative">
              <div className="relative pb-[41px]">
                <div className="rounded-3xl text-center text-3xl flex justify-center leading-[59px] border font-bold text-[#97a7d5] border-[#97a7d5] w-fit mx-auto px-10 my-20">
                  하이오더 도입 후
                </div>
                <div className="flex justify-center">
                  <img src="2023050217211100950258.webp" alt="도입 후" />
                </div>
              </div>
            </div>
          </div>
          {/* 7번 */}
          <div className="bg-[#F4F7F9] py-20">
            <div className="container mx-auto px-4 bg-[#F4F7F9]">
              <div className="container lg:mx-auto py-10 my-6 text-center flex items-center justify-center relative">
                <h2 className="lg:text-[40px] text-[20px] font-bold text-[#D92C38] ">
                  KT 하이 오더
                </h2>
                <h2 className="lg:text-[40px] text-[20px] font-bold ">
                  를 사용해야 하는 이유!
                </h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                <div className="bg-white rounded-2xl flex justify-center items-center flex-col">
                  <h1 className="text-2xl font-bold py-10">
                    믿을 수 있는 KT 직접 개통
                  </h1>
                  <img
                    src="icons8-building-96.png"
                    alt="building"
                    className="w-1/4"
                  />
                  <ul className="py-10 text-gray-500 leading-10 list-disc px-10">
                    <li>인터넷 1위 기업 KT의 안정적인 네트워크</li>
                    <li>전국 어디서나 KT 직영 설치 및 AS</li>
                    <li>서비스 장애 시 원스탑 처리</li>
                  </ul>
                </div>
                <div className="bg-white rounded-2xl flex justify-center items-center flex-col">
                  <h1 className="text-2xl font-bold py-10">
                    다양한 고객 편의 기능 제공
                  </h1>
                  <img
                    src="icons8-split-payment-64.png"
                    alt="card"
                    className="w-1/4"
                  />
                  <ul className="py-10 text-gray-500 leading-10 list-disc px-10">
                    <li>
                      다양한 결제 기능 제공(타사 미제공) (각종 페이 및 모바일
                      쿠폰)
                    </li>
                    <li>
                      10개국어 지원(업계 최다)으로 외국인까지 쉽게 주문 가능
                    </li>
                    <li>상황별 서빙 직원 호출</li>
                  </ul>
                </div>
                <div className="bg-white rounded-2xl flex justify-center items-center flex-col">
                  <h1 className="text-2xl font-bold py-10">우수한 품질</h1>
                  <img
                    src="icons8-service-48.png"
                    alt="service"
                    className="w-1/4"
                  />
                  <ul className="py-10 text-gray-500 leading-10 list-disc px-10">
                    <li>
                      엄격한 통신사 품질&보안 검증을 거친 태블릿 + 3년 무상 A/S
                    </li>
                    <li>
                      플라스틱 전용 케이스 & 우수한 터치감과 반응속도를 가진
                      태블릿
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* 10번 */}
          <div className="container lg:mx-auto py-10 my-6 text-center flex items-center justify-center relative">
            <h2 className="lg:text-[40px] text-[20px] font-bold text-[#D92C38] ">
              사장님께서 원하시는 타입
            </h2>
            <h2 className="lg:text-[40px] text-[20px] font-bold ">
              으로 선택하세요!
            </h2>
          </div>
          {/* 11번 */}
          <div className="container mx-auto grid justify-center gap-4 grid-cols-1 md:grid-cols-2 mb-[100px]">
            <div className="flex flex-col items-center p-4 rounded-2xl relative border">
              <h3 className="my-3 text-3xl font-semibold mb-10">
                빠른 테이블 회전을 원하는 매장
              </h3>
              <img src="pi1560-type_detail-1.png" alt="회전율"></img>
              <div className="space-y-1 leadi mt-10">
                {/* <img src="pi1560-type_txtimg-1.png" alt="sub"></img> */}
                <h1 className="text-2xl font-bold">
                  손님이 앉은 자리에서 주문과 결제까지
                </h1>
              </div>
            </div>
            <div className="flex flex-col items-center p-4 rounded-2xl relative border">
              <h1 className="my-3 text-3xl font-semibold mb-10">
                추가 주문많은 매장
              </h1>
              <img src="pi1560-type_detail-2.png" alt="인건비 절감"></img>
              <div className="space-y-1 leadi mt-10">
                <h2 className="text-2xl font-bold">
                  하이오더로 주문 후 퇴장 시 카운터 결제
                </h2>
              </div>
            </div>
          </div>
          {/* 12번 */}
          <div className="bg-[#F4F7F9]">
            <div className="container mx-auto px-4 bg-[#F4F7F9] flex flex-col rounded-3xl py-10">
              <div className="flex justify-center items-center pb-12">
                <span className="inline-block relative">
                  <span className="block absolute bottom-0 left-0 w-full h-3 z-0 bg-[#bdedf0]" />
                  <em className="block relative text-4xl z-[1] font-bold">
                    하이오더 구성
                  </em>
                </span>
              </div>
              <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
                <div className="">
                  <dl className="py-[38px] bg-white rounded-3xl">
                    <dt className="text-2xl text-center h-[260px] relative">
                      <img
                        src="pi1560-composition_item-1.png"
                        alt=""
                        className="block w-[306px] h-[232px] mb-[23px] left-1/2 translate-x-[-50%] absolute"
                      ></img>
                    </dt>
                    <dd className="mt-[4px] text-lg text-center">
                      <h1 className="text-3xl font-bold">테블릿 메뉴판</h1>
                      <br />
                      <span className="flex justify-center">
                        <h1 className="font-bold">손님이 직접 주문</h1>
                        하는 솔루션 탑재 태블릿
                      </span>
                    </dd>
                  </dl>
                </div>
                <div className="">
                  <dl className="py-[38px] bg-white rounded-3xl">
                    <dt className="text-2xl text-center h-[260px] relative">
                      <img
                        src="pi1560-composition_item-2.png"
                        alt=""
                        className="block  mb-[23px] left-1/2 translate-x-[-50%] absolute"
                      ></img>
                    </dt>
                    <dd className="mt-[4px] text-lg text-center">
                      <h1 className="text-3xl font-bold">태블릿 알림판</h1>
                      <br />
                      <span className="flex justify-center">
                        <h1 className="font-bold">사장님이 주문 내용을 확인</h1>
                        하는 태블릿
                      </span>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="border mt-12 bg-white pb-10 relative rounded-2xl">
                <span className="left-1/2 translate-x-[-50%] top-[-35px] absolute ">
                  <img src="pi1560-composition-add.png" alt="플러스" />
                </span>
                <div className="flex justify-center items-center pb-12 pt-12">
                  <span className="inline-block relative">
                    <span className="block absolute bottom-0 left-0 w-full h-3 z-0 bg-[#bdedf0]" />
                    <h1 className="block relative lg:text-4xl text-2xl z-[1] font-bold">
                      부속품 패키지 무상제공
                    </h1>
                  </span>
                </div>
                <div className="grid lg:grid-cols-4 gap-8 md:grid-cols-2 grid-cols-1">
                  <div className="flex justify-center flex-col items-center text-center">
                    <span className="justify-start flex-auto">
                      <img src="pi1560-composition-add_item-1.png" alt=""></img>
                    </span>
                    <h1 className="font-bold text-xl">케이스 & 거치대</h1>
                    <p>
                      태블릿 보호용 케이스와 <br />
                      테이블 고정 거치대
                    </p>
                  </div>
                  <div className="flex justify-center flex-col items-center text-center h-full">
                    <span className="justify-start flex-auto">
                      <img src="pi1560-composition-add_item-2.png" alt=""></img>
                    </span>
                    <h1 className="font-bold text-xl">보조 배터리+충전기</h1>
                    <p>
                      별도 전원 공사 없이 <br />
                      보조배터리로 전원 공급
                    </p>
                  </div>
                  <div className="flex justify-center flex-col items-center text-center">
                    <span className="flex-auto">
                      <img src="pi1560-composition-add_item-3.png" alt=""></img>
                    </span>
                    <h1 className="font-bold text-xl">와이파이 AP</h1>
                    <p>
                      하이오더 전용 별도 와이파이공유기 <br />
                      (KT인터넷고객은 KT AP <br /> 타사 인터넷고객은 사설
                      AP제공)
                    </p>
                  </div>
                  <div className="flex justify-center flex-col items-center text-center ">
                    <span className="flex-auto">
                      <img src="pi1560-composition-add_item-4.png" alt=""></img>
                    </span>
                    <h1 className="font-bold text-xl font-Noto">설치비</h1>
                    <p>
                      태블릿 보호용 케이스와 <br />
                      테이블 고정 거치대
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-5 text-gray-500">*하이오더 16대 설치 기준</div>
            </div>
          </div>
          {/*  */}
          <div className="container mx-auto grid justify-center gap-4 grid-cols-1 md:grid-cols-2 mb-5 mt-20">
            <div className="flex flex-col items-center p-4 rounded-2xl relative border pb-10">
              <h1 className="my-3 lg:text-3xl text-2xl font-semibold mb-10">
                타입 A - 메뉴판 선불형
              </h1>
              <img src="2023050217323100858816.webp" alt="회전율"></img>
              <h2 className="font-bold lg:text-2xl text-base">
                빠른 테이블 회전을 원하는 매장
              </h2>
              <p className="lg:text-lg text-sm text-gray-500">
                손님이 앉은 자리에서 주문과 결제까지
              </p>
            </div>
            <div className="flex flex-col items-center p-4 rounded-2xl relative border pb-10">
              <h1 className="my-3 lg:text-3xl text-2xl font-semibold mb-10">
                타입 B - 메뉴판 후불형
              </h1>
              <img src="2023050217325400825538.webp" alt="sub"></img>
              <h2 className="font-bold lg:text-2xl text-base">
                추가 주문이 많은 매장
              </h2>
              <p className="lg:text-lg text-sm text-gray-500">
                하이오더로 주문 후 퇴장 시 카운터 결제
              </p>
            </div>
          </div>
          <div className="container mx-auto">
            <p className="text-gray-500">
              * 선불형을 원하시는 경우 VAN 대리점과 카드 결제기는 별도
              계약하셔야 합니다.
            </p>
          </div>
          {/*  */}
          <div className="container mx-auto border-b-[3px] border-black mb-16 mt-16 pb-5">
            <h1 className="text-3xl font-medium ">요금 안내</h1>
          </div>
          <Cost />
          {/*  */}
          <div className="border-b-[3px] border-black mb-16 mt-16 pb-5 bg-[#f5f5f5] pt-20">
            <div className="flex justify-center align-middle">
              <h1 className="xl:text-6xl lg:text-5xl md:text-4xl text-2xl leading-loose font-bold">
                <span className="text-[#D92C38] font-black">하이오더</span> 도입
                신청 및 상담은
                <br /> <span className="text-[#D92C38] font-black">무료</span>
                이니 편하게 접수해 주세요
              </h1>
            </div>
            <div className="">
              <section className=" pb-[100px]">
                <form
                  onSubmit={handleSubmit}
                  className="container mt-16 lg:max-w-[70%] max-w-[85%] md:mx-auto mx-auto bg-white p-10 rounded-3xl"
                >
                  <div className="grid grid-cols-1 gap-x-8 gap-y-6 w-[90%]">
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-semibold md:text-lg leading-6 text-gray-900">
                        이름 <label className="text-red-500">*</label>
                      </label>
                      <div className="mt-2.5">
                        <input
                          required
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="name"
                          placeholder="이름을 입력해주세요."
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={e =>
                            setDocument({ ...document, name: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label className="block text-sm font-semibold md:text-lg leading-6 text-gray-900">
                        상호명 <label className="text-red-500">*</label>
                      </label>
                      <div className="mt-2.5">
                        <input
                          required
                          type="text"
                          name="company"
                          id="company"
                          autoComplete="organization"
                          placeholder="상호명을 입력해주세요."
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={e =>
                            setDocument({
                              ...document,
                              company: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-semibold md:text-lg leading-6 text-gray-900">
                        이메일 <label className="text-red-500">*</label>
                      </label>
                      <div className="mt-2.5">
                        <input
                          required
                          type="email"
                          name="email"
                          id="email"
                          autoComplete="email"
                          placeholder="이메일을 입력해주세요."
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={e =>
                            setDocument({ ...document, email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-semibold md:text-lg leading-6 text-gray-900">
                        연락처 <label className="text-red-500">*</label>
                      </label>
                      <div className="mt-2.5">
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          required
                          autoComplete="tel"
                          placeholder="연락처를 입력해주세요."
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={e =>
                            setDocument({ ...document, phone: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-semibold md:text-lg leading-6 text-gray-900">
                        설치 장소 <label className="text-red-500">*</label>
                      </label>
                      <div className="mt-2.5">
                        <input
                          type="tel"
                          name="place"
                          id="place"
                          required
                          autoComplete="place"
                          placeholder="설치 장소를 입력해주세요."
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={e =>
                            setDocument({ ...document, place: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-semibold md:text-lg leading-6 text-gray-900">
                        문의내용
                      </label>
                      <div className="mt-2.5">
                        <textarea
                          name="message"
                          id="message"
                          rows="4"
                          placeholder="문의내용을 입력해주세요"
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={e =>
                            setDocument({
                              ...document,
                              content: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="block text-sm font-semibold leading-6 text-gray-900 pt-10">
                    <h1>[필수] 고객정보 수집 동의</h1>
                  </div>
                  <table className="table-fixed w-full border-separate text-center">
                    <colgroup>
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                    </colgroup>
                    <thead className="bg-[#f1f2fa]">
                      <tr>
                        <th
                          scope="col"
                          className="border-t-8 border-t-[#ccc] border-b-2 border-b-black"
                        >
                          목적
                        </th>
                        <th
                          scope="col"
                          className="border-t-8 border-t-[#ccc] border-b-2 border-b-black"
                        >
                          항목
                        </th>
                        <th
                          scope="col"
                          className="border-t-8 border-t-[#ccc] border-b-2 border-b-black"
                        >
                          보유기간
                        </th>
                        <th
                          scope="col"
                          className="border-t-8 border-t-[#ccc] border-b-2 border-b-black"
                        >
                          동의여부
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="lg:text-lg text-xs text-gray-500 font-semibold">
                        <td className=" border-b-2 border-b-gray-300">
                          상담접수 시 답변 용도
                        </td>
                        <td className=" border-b-2 border-b-gray-300">
                          회사명/이름, 연락처, 이메일
                        </td>
                        <td className=" border-b-2 border-b-gray-300">
                          처리 완료 후<br /> 6개월
                        </td>
                        <td className=" border-b-2 border-b-gray-300">
                          <span className="inline-block relative">
                            <input
                              type="radio"
                              id="radios1-1"
                              name="options1"
                              className="w-5 h-5 top-1/2 left-0 absolute translate-y-[-50%]"
                              required
                            />
                            <label
                              className="pl-[30px] lg:text-lg text-xs"
                              htmlFor="radios1-1"
                            >
                              동의함
                            </label>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-10 flex justify-center">
                    <button
                      type="submit"
                      className="bg-[#d71825d8] text-white font-bold rounded-2xl py-2 min-w-[200px] w-1/4 block"
                    >
                      <label>상담 신청하기</label>
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
