import { formattedNumber } from '../util/utils'

export default function Cost() {
  const service = [
    {
      name: '하이오더 알림판',
      threeYearPrice: 14300,
      twoYearPrice: 17050,
      note: '',
    },
    {
      name: '하이오더 메뉴판 후불형',
      threeYearPrice: 14300,
      twoYearPrice: 17050,
      note: '',
    },
    {
      name: '하이오더 메뉴판 선불형',
      threeYearPrice: 16500,
      twoYearPrice: 20350,
      note: 'KICC, 스마트로, KSNET, KIS 카드리더기 중 1종 무료제공',
    },
    {
      name: '하이오더 메뉴판 선불형 (NICE 카드리더기)',
      threeYearPrice: 17600,
      twoYearPrice: 22000,
      note: 'NICE카드리더기 무료제공',
    },
  ]
  const terminal = [
    {
      name: '메뉴판/알림판 10인치',
      threeYearPrice: 6600,
      twoYearPrice: 9350,
      SinglePrice: 202400,
    },
    {
      name: '알림판 15인치',
      threeYearPrice: 9900,
      twoYearPrice: 14850,
      SinglePrice: 314600,
    },
  ]
  return (
    <div className="relative flex flex-col text-gray-700 bg-white  bg-clip-border rounded-xl mx-auto container">
      <p className="justify-end flex text-gray-500 text-lg">
        * 부가세가 포한된 실제 지불금액입니다.
      </p>
      <h1 className="text-xl font-semibold mb-5">서비스 이용료(1대당)</h1>
      <table className="text-left table-auto">
        <thead>
          <tr className="lg:text-xl text-sm text-center">
            <th className="p-4 border border-white bg-[#536894]">
              <p className="block font-sans antialiased font-normal leading-none "></p>
            </th>
            <th className="p-4 border border-white bg-[#4d5973] text-white">
              <p className="block font-sans antialiased font-normal leading-none ">
                3년 약정
              </p>
            </th>
            <th className="p-4 border border-white bg-[#4d5973] text-white">
              <p className="block font-sans antialiased font-normal leading-none ">
                2년 약정
              </p>
            </th>
            <th className="p-4 border border-white bg-[#4d5973] text-white">
              <p className="block font-sans antialiased font-normal leading-none ">
                비고
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {service.map((data, index) => (
            <tr
              className="even:bg-blue-gray-50/50 text-center lg:text-xl text-xs"
              key={index}
            >
              <td className="p-4 border-r border-b border-gray-300">
                <p className="block font-sans font-extrabold antialiased leading-normal text-blue-gray-900">
                  {data.name}
                </p>
              </td>
              <td className="p-4 border-r border-b border-gray-300">
                <p className="block font-sans antialiased font-normal leading-normal text-gray-500">
                  {formattedNumber(data.threeYearPrice)}
                </p>
              </td>
              <td className="p-4 border-r border-b border-gray-300">
                <p className="block font-sans antialiased font-normal leading-normal text-gray-500">
                  {formattedNumber(data.twoYearPrice)}
                </p>
              </td>
              <td className="p-4 border- border-b border-gray-300">
                <p className="block font-sans antialiased font-normal leading-normal text-gray-500">
                  {data.note}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ul>
        <li className="relative lg:text-xl text-xs mt-5">
          <p className="ml-3">* 요금 선납 시 총 납부금액의 10% 할인</p>
        </li>
      </ul>
      <h1 className="text-xl font-semibold mb-5 mt-14">단말대금 (1대당)</h1>
      <table className="text-left table-auto">
        <thead>
          <tr className="lg:text-xl text-xs text-center">
            <th className="p-4 border border-white bg-[#536894]">
              <p className="block font-sans antialiased font-normal leading-none "></p>
            </th>
            <th className="p-4 border border-white bg-[#4d5973] text-white">
              <p className="block font-sans antialiased font-normal leading-none ">
                3년 약정
              </p>
            </th>
            <th className="p-4 border border-white bg-[#4d5973] text-white">
              <p className="block font-sans antialiased font-normal leading-none ">
                2년 약정
              </p>
            </th>
            <th className="p-4 border border-white bg-[#4d5973] text-white">
              <p className="block font-sans antialiased font-normal leading-none ">
                단말 대금 일시불 금액
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {terminal.map((data, index) => (
            <tr
              className="even:bg-blue-gray-50/50 text-center lg:text-xl text-xs"
              key={index}
            >
              <td className="p-4 border-r border-b border-gray-300">
                <p className="block font-sans font-extrabold antialiased leading-normal text-blue-gray-900">
                  {data.name}
                </p>
              </td>
              <td className="p-4 border-r border-b border-gray-300">
                <p className="block font-sans antialiased font-normal leading-normal text-gray-500">
                  월 {formattedNumber(data.threeYearPrice)}
                </p>
              </td>
              <td className="p-4 border-r border-b border-gray-300">
                <p className="block font-sans antialiased font-normal leading-normal text-gray-500">
                  월 {formattedNumber(data.twoYearPrice)}
                </p>
              </td>
              <td className="p-4 border- border-b border-gray-300">
                <p className="block font-sans antialiased font-normal leading-normal text-gray-500">
                  {formattedNumber(data.SinglePrice)}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
