import { useContext } from 'react'
import { Link } from 'react-router-dom'
import SubmitModalContext from '../context/SubmitModalProvider'

export default function Header() {
  const { setIsOpen } = useContext(SubmitModalContext)
  return (
    <header className="lg:px-16 px-4 bg-white flex flex-wrap items-center py-4 shadow-md space-y-2 ">
      <div className="flex-1 flex justify-between items-center">
        <Link to="/">
          <div className="flex">
            <img
              src="gnb-ktlogo.png"
              alt="logo"
              className="w-[31px] h-[26px]"
            />
            <h1 className="font-bold text-2xl ml-3">하이오더 전문업체</h1>
          </div>
        </Link>
      </div>

      <input className="hidden" type="checkbox" id="menu-toggle" />

      <nav>
        <ul
          onClick={() => setIsOpen(true)}
          className="md:flex items-center justify-between text-gray-500 pt-4 md:pt-0 text-xl font-bold cursor-pointer"
        >
          <li>제품 문의</li>
        </ul>
      </nav>
    </header>
  )
}
